body {
 margin: 0;
 padding: 0;
 height: 100vh;
 /* background: linear-gradient(#EEF7FD, white, #EEF7FD, white); */
 background: linear-gradient(#EEF7FD,  white);
}

.header {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 background-color: white;
 height: 75px;
 z-index: 1000;
}


.bdy {
 margin-top: 75px;
}

.xls {
 margin-left: 30px;
 font-size: 30px;
 font-family: Arial Black;
 color: rgb(71, 163, 71);
}

.n {
 font-size: 30px;
 font-family: Arial Black;
 color: black;
}

.sms {
 font-size: 30px;
 font-family: Arial Black;
 color: rgb(27, 165, 230);
}

p {
 padding-top: 50px;
 font-size: 40px;
}